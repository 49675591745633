import styled from 'styled-components';

export const TitleContainer = styled.div`
  background: #fff url(${(props) => props.$background}) repeat-x top center;
  height: 640px;
  color: #6e2b8b;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.02em;
  @media (max-width: 991px) {
    height: auto;
    padding-top: 20px;
    background: #fff url(${(props) => props.$backgroundMobile}) repeat-x top
      center;
    background-size: 880px;
  }
`;

export const TitleStyle = styled.div`
  width: 800px;
  height: auto;
  margin: 0 auto;
  padding: 70px 0px 0px 0px;
  text-align: center;
  @media (max-width: 991px) {
    width: 100%;
    height: auto;
    padding: 240px 35px 10px 35px;
    text-align: center;
  }
`;

export const TitleLeft = styled.div`
  width: 600px;
  float: left;
  @media (max-width: 991px) {
    width: 100%;
    float: none;
  }
`;

export const TitleRight = styled.div`
  width: 200px;
  float: right;
  @media (max-width: 991px) {
    width: 100%;
    float: none;
  }
`;
export const HeadingH1 = styled.h1`
  font-size: 34px;
  line-height: 40px;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 0;
  color: #6e2b8b;
  font-family: 'Figtree-Bold', arial, sans-serif;
  font-weight: bold;
  @media (max-width: 991px) {
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 20px;
  }
  b {
    font-family: 'Figtree-Bold', arial, sans-serif;
    font-weight: bold;
  }
`;

export const HeadingH2 = styled.h2`
  font-size: 18px;
  line-height: 28px;
  font-family: 'Figtree-Regular';
  margin-bottom: 25px;
  padding: 0;
  font-weight: normal !important;
  color: #6e2b8b;
  text-align: center;
  @media (max-width: 870px) {
    font-size: 14px;
    line-height: 24px;
    font-family: 'Figtree-Regular';
    margin-bottom: 25px;
  }
`;

export const ImageStyle = styled.img`
  width: 55%;
  height: auto;
  border: 0;
  vertical-align: top;
  @media (max-width: 991px) {
    max-width: 270px;
  }
`;

export const HeadingH3 = styled.h3`
  color: #6e2c8b !important;
  background: #ffc018;
  font-size: 17px !important;
  padding: 10px 50px;
  margin: 0;
  margin-bottom: 60px;
  font-family: 'Figtree-Bold', arial, sans-serif;
  font-weight: bold;
  @media (max-width: 991px) {
    font-size: 15px !important;
    padding: 15px;
    margin-bottom: 60px;
  }
`;
