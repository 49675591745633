import React from 'react';
import * as Styled from './slpTopHeaderStyle';

const SlpTopHeader = ({ sectionData }) => {
  return (
    <Styled.TitleContainer
      $background={sectionData?.assets[0]?.file?.url}
      $backgroundMobile={sectionData?.assets[1]?.file?.url}
    >
      <Styled.TitleStyle>
        <Styled.TitleLeft>
          <Styled.HeadingH1>
            {sectionData?.headerWithText?.title}
          </Styled.HeadingH1>
          <Styled.HeadingH2>
            {sectionData?.headerWithText?.headerText}
          </Styled.HeadingH2>
          {sectionData?.textWithPaths && (
            <Styled.HeadingH3>
              {sectionData?.textWithPaths[0]?.text}
            </Styled.HeadingH3>
          )}
        </Styled.TitleLeft>
        <Styled.TitleRight>
          <Styled.ImageStyle src={sectionData?.image?.file?.url} />
        </Styled.TitleRight>
      </Styled.TitleStyle>
    </Styled.TitleContainer>
  );
};
export default SlpTopHeader;
